/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./spud-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../spud/spud.component.ngfactory";
import * as i3 from "../spud/spud.component";
import * as i4 from "@angular/common";
import * as i5 from "./spud-list.component";
var styles_SpudListComponent = [i0.styles];
var RenderType_SpudListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SpudListComponent, data: {} });
export { RenderType_SpudListComponent as RenderType_SpudListComponent };
function View_SpudListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-spud", [], null, null, null, i2.View_SpudComponent_0, i2.RenderType_SpudComponent)), i1.ɵdid(2, 114688, null, 0, i3.SpudComponent, [], { spud: [0, "spud"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SpudListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SpudListComponent_1)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.spuds; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SpudListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-spud-list", [], null, null, null, View_SpudListComponent_0, RenderType_SpudListComponent)), i1.ɵdid(1, 114688, null, 0, i5.SpudListComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SpudListComponentNgFactory = i1.ɵccf("app-spud-list", i5.SpudListComponent, View_SpudListComponent_Host_0, {}, {}, []);
export { SpudListComponentNgFactory as SpudListComponentNgFactory };
