/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nav-home.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./nav-home.component";
var styles_NavHomeComponent = [i0.styles];
var RenderType_NavHomeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavHomeComponent, data: {} });
export { RenderType_NavHomeComponent as RenderType_NavHomeComponent };
export function View_NavHomeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "button", [["routerLink", "/potatoes"], ["routerLinkActive", "active"], ["skipLocationChange", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, [[1, 4]], 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { skipLocationChange: [0, "skipLocationChange"], routerLink: [1, "routerLink"] }, null), i1.ɵdid(2, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, [2, i2.RouterLink], [2, i2.RouterLinkWithHref]], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(-1, null, ["Potatoes"])), (_l()(), i1.ɵeld(6, 0, null, null, 5, "button", [["routerLink", "/spuds"], ["routerLinkActive", "active"], ["skipLocationChange", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 16384, [[3, 4]], 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { skipLocationChange: [0, "skipLocationChange"], routerLink: [1, "routerLink"] }, null), i1.ɵdid(8, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, [2, i2.RouterLink], [2, i2.RouterLinkWithHref]], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 3, { links: 1 }), i1.ɵqud(603979776, 4, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(-1, null, ["spuds"])), (_l()(), i1.ɵeld(12, 0, null, null, 5, "button", [["routerLink", "/tasks"], ["routerLinkActive", "active"], ["skipLocationChange", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 16384, [[5, 4]], 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { skipLocationChange: [0, "skipLocationChange"], routerLink: [1, "routerLink"] }, null), i1.ɵdid(14, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, [2, i2.RouterLink], [2, i2.RouterLinkWithHref]], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 5, { links: 1 }), i1.ɵqud(603979776, 6, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(-1, null, ["tasks"]))], function (_ck, _v) { var currVal_0 = ""; var currVal_1 = "/potatoes"; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = "active"; _ck(_v, 2, 0, currVal_2); var currVal_3 = ""; var currVal_4 = "/spuds"; _ck(_v, 7, 0, currVal_3, currVal_4); var currVal_5 = "active"; _ck(_v, 8, 0, currVal_5); var currVal_6 = ""; var currVal_7 = "/tasks"; _ck(_v, 13, 0, currVal_6, currVal_7); var currVal_8 = "active"; _ck(_v, 14, 0, currVal_8); }, null); }
export function View_NavHomeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-nav-home", [], null, null, null, View_NavHomeComponent_0, RenderType_NavHomeComponent)), i1.ɵdid(1, 114688, null, 0, i3.NavHomeComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NavHomeComponentNgFactory = i1.ɵccf("app-nav-home", i3.NavHomeComponent, View_NavHomeComponent_Host_0, {}, {}, []);
export { NavHomeComponentNgFactory as NavHomeComponentNgFactory };
