/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./task-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./task-list.component";
var styles_TaskListComponent = [i0.styles];
var RenderType_TaskListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TaskListComponent, data: {} });
export { RenderType_TaskListComponent as RenderType_TaskListComponent };
export function View_TaskListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["task-list works!"]))], null, null); }
export function View_TaskListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-task-list", [], null, null, null, View_TaskListComponent_0, RenderType_TaskListComponent)), i1.ɵdid(1, 114688, null, 0, i2.TaskListComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TaskListComponentNgFactory = i1.ɵccf("app-task-list", i2.TaskListComponent, View_TaskListComponent_Host_0, {}, {}, []);
export { TaskListComponentNgFactory as TaskListComponentNgFactory };
