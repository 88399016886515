/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./potato-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../potato/potato.component.ngfactory";
import * as i3 from "../potato/potato.component";
import * as i4 from "@angular/common";
import * as i5 from "./potato-list.component";
var styles_PotatoListComponent = [i0.styles];
var RenderType_PotatoListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PotatoListComponent, data: {} });
export { RenderType_PotatoListComponent as RenderType_PotatoListComponent };
function View_PotatoListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-potato", [], null, null, null, i2.View_PotatoComponent_0, i2.RenderType_PotatoComponent)), i1.ɵdid(2, 114688, null, 0, i3.PotatoComponent, [], { potato: [0, "potato"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_PotatoListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PotatoListComponent_1)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.potatoes; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_PotatoListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-potato-list", [], null, null, null, View_PotatoListComponent_0, RenderType_PotatoListComponent)), i1.ɵdid(1, 114688, null, 0, i5.PotatoListComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PotatoListComponentNgFactory = i1.ɵccf("app-potato-list", i5.PotatoListComponent, View_PotatoListComponent_Host_0, {}, {}, []);
export { PotatoListComponentNgFactory as PotatoListComponentNgFactory };
